@import url(https://fonts.googleapis.com/css?family=Nunito:300,300i,400,400i,600,600i,700,700i,800,800i,900,900i&display=swap);

$font-type: 'Nunito', serif;  

$bg-login: white;
$primary: #E84A34;
$dark: #b33322;
$ligth: #757575;
$gray: #fafafa;

$nav-height: 60px;
$device-sm: 500px;

$device-sm: 540px;
$device-md: 790px;
$device-lg: 960px;
$device-xl: 1140px;
$device-mv: 360px;
